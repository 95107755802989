import { AdminChangePassword } from '../translations-types';

const translations: AdminChangePassword = {
  passwordHasBeenChanged: 'Het wachtwoord is succesvol aangepast.',
  passwordHasBeenSet: 'Wachtwoord is succesvol ingesteld.',
  changePassword: 'Wachtwoord aanpassen',
  setNewPassword: 'Stel nieuw wachtwoord in',
  confirmPassword: 'Bevestig wachtwoord',
  user: 'Gebruiker: {{email}}',
};

export default translations;
