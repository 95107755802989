import { Questionnaire } from '../translations-types';

const translations: Questionnaire = {
  day: 'Day',
  month: 'Month',
  year: 'Year',
  multipleAnswerArePossible: 'Multiple answers are possible.',
  enterYourOption: 'Enter your option',
  other: 'Other',
  question: 'Question',
  goToDashboard: 'Go to dashboard',
  resultsWillBeReviewed: 'Your submission results are added to your customer profile.',
  thankYouForSubmitting: 'Thank you for submitting!',
  signOut: 'Sign out',
  customerTitle: 'Customer title',
  employeeTitle: 'Employee title',
  icon: 'Icon',
  errorOpeningUrl: 'An error has occurred while opening page',
  resenceQuestionnaire: 'Re-sence questionnaire',
  resenceQuestionnaireTooltip: 'Re-sence questionnaires are managed by Re-sence and are copied for all new Organisations',
  customQuestionnaire: 'Custom questionnaire',
};

export default translations;
