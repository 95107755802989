import { Questionnaires } from '../translations-types';

const translations: Questionnaires = {
  newOption: 'New option',
  options: 'Options',
  option: 'Option',
  startTyping: 'Start typing to add a new option',
  question: 'Question',
  type: 'Type',
  isRequired: 'Required?',
  allowMultipleValues: 'Allow multiple values?',
  allowCustomValue: 'Allow to choose a custom value?',
  displayLogic: 'Display logic?',
  displayLogicTooltip: 'Display question conditionally.',
  editQuestionnaire: 'Edit questionnaire',
  createQuestionnaire: 'Create questionnaire',
  viewQuestionnaire: 'View questionnaire',
  addQuestion: '+ Add question',
  rating: 'Rating',
  containsNumber: 'Contains number',
  containsDate: 'Contains date',
  containsText: 'Contains text',
  containsAnyText: 'Contains any text',
  elementType: 'Element type',
  directElementDescription: 'Direct - the element is always displayed with the answer to the question.',
  conditionalElementDescription: 'Conditional - the element is always displayed only if the answer matches the condition.',
  persistentElementDescription: 'Persistent - the element is always displayed with a persistent text and image.',
  questionnaire: 'Questionnaire',
  questionnaires: 'Questionnaires',
  labelPosition: 'Label position',
  label: 'Label',
  labelPostfix: 'Label postfix',
  labelPostfixTooltip: 'Piece of text to append after the label, e.g. "X per day".',
  maxLabelWidth: 'Max label width',
  chooseImage: 'Choose image',
  editOverview: 'Edit customer dashboards',
  newQuestion: 'New question',
  successfullyCreated: 'Successfully created questionnaire.',
  successfullyUpdated: 'Successfully updated questionnaire.',
  questionnaireName: 'Questionnaire name',
  questionsCount: 'Questions count',
  addQuestionnaire: 'Add questionnaire',
  variant: 'Variant',
  body: 'Body',
  image: 'Image',
  videoUrl: 'Video URL',
  onlyYtVimeo: 'Only YouTube and Vimeo videos are supported.',
  messageTemplate: 'Message template',
  hidden: 'Hidden?',
  questionXName: 'Question {{number}} name',
  questionXBody: 'Question {{number}} body',
  questionXOptionYCustomerTitle: 'Question {{number}} option {{number2}} customer title',
  questionXOptionYEmployeeTitle: 'Question {{number}} option {{number2}} employee title',
  resenceQuestionnaires: 'Re-sence questionnaires',
  customQuestionnaires: 'Custom questionnaires',
};

export default translations;
