import { Customer } from '../translations-types';

const translations: Customer = {
  removeElement: 'Remove element?',
  editCustomer: 'Edit customer',
  fill: 'Fill',
  fillNow: 'Fill questionnaire now',
  questionnaireSent: 'Questionnaire successfully sent to',
  send: 'Send',
  sendToCustomer: 'Send questionnaire to customer',
  messageType: 'Message type',
  questionnaire: 'Questionnaire',
  questionnaireToSend: 'Questionnaire to send',
  submissionActivity: 'Submission activity',
  addCustomer: 'Add customer',
  loadingCustomer: 'Loading customer...',
  customerNotFound: 'Customer not found',
  customElement: 'Custom element',
  enterSearchCriteria: 'Enter search criteria',
  defaultMessagingType: 'Default messaging type',
  questionnaireNotSelected: 'Questionnaire not selected.',
  notEnoughCredits: 'Not enough Whatsapp credits.',
  customerDoesNotHaveAPhoneNumber: 'Customer does not have a phone number.',
  sendTo: 'Send to',
  purchaseMoreCredits: 'Purchase more credits',
  customerDefault: 'Customer default',
  sendQuestionnaires: 'Send questionnaires',
  sendMultipleQuestionnaires: 'Send multiple questionnaires',
  failedToSendQuestionnaireTo: 'Failed to send questionnaire to: {{names}}.',
  sentQuestionnaireToCount: 'Sent questionnaire to {{count}} customers.',
  selectCustomersCount: 'Select customers ({{count}})',
  allowedMessagingTypes: 'Allowed messaging types',
  updateNotificationPreferencesDescription:
    'This way you can indicate how you want to receive your messages or if you do not want to receive messages at all, you can turn everything off. PLEASE NOTE: If you do NOT wish to receive messages, we will no longer be able to offer the Re-sence experience and will send you new questionnaires. Of course we respect your decision. You can adjust your choice later if you wish to get the Re-sence experience again.',
  updateNotificationPreferencesTitle: 'Update notification preferences',
  customerDoesNotAcceptThisNotificationType: 'Customer does not accept this notification type',
  sendAdviceNotification: 'Send advice Notification',
  notificationSent: 'Notification successfully sent to',
};

export default translations;
