import {
  CustomerGender,
  EmployeeActivityType,
  MappingElementLabelWidth,
  CustomerActivityType,
  QuestionnaireType,
  QuestionType,
  SupportedLanguage,
  CustomerCommentType,
  AdviceType,
  SubscriptionOptionFeature,
  OrganisationMessagingType,
  CustomerConsentStatus,
  DocumentType,
} from 'common/api/generated-api';
import { DashboardType, LabelPosition, MappingElementType } from 'common/types/dashboard-configuration';
import { Types } from '../translations-types';

const translations: Types = {
  [QuestionnaireType.CustomerQuestionnaire]: 'Customer Questionnaire',
  [QuestionnaireType.EmployeeQuestionnaire]: 'Employee Questionnaire',
  [QuestionType.Boolean]: 'Boolean',
  [QuestionType.Date]: 'Date',
  [QuestionType.Image]: 'Image',
  [QuestionType.Number]: 'Number',
  [QuestionType.Option]: 'Dropdown',
  [QuestionType.Rating]: 'Rating',
  [QuestionType.String]: 'Text',
  [QuestionType.Information]: 'Information',
  [CustomerActivityType.AnswerUpdated]: 'Answer updated',
  [CustomerActivityType.SubmissionCreated]: 'Questionnaire sent',
  [CustomerActivityType.Login]: 'Login',
  [CustomerActivityType.FailedLogin]: 'Failed login',
  [CustomerActivityType.CodeRequest]: 'Code request',
  [CustomerActivityType.ProfileUpdate]: 'Profile update',
  [CustomerActivityType.DocumentStatusUpdated]: 'Document status updated',
  [CustomerActivityType.DocumentSent]: 'Document sent',
  [CustomerActivityType.DocumentDelete]: 'Document deleted',
  [LabelPosition.Bottom]: 'Bottom',
  [LabelPosition.Left]: 'Left',
  [LabelPosition.Right]: 'Right',
  [LabelPosition.Top]: 'Top',
  [LabelPosition.NoLabel]: 'No label',
  [SupportedLanguage.Nl]: 'Dutch',
  [SupportedLanguage.En]: 'English',
  [MappingElementType.Conditional]: 'Conditional',
  [MappingElementType.Direct]: 'Direct',
  [MappingElementType.Persistent]: 'Persistent',
  [MappingElementLabelWidth.Default]: 'Default',
  [MappingElementLabelWidth.Short]: 'Short',
  [MappingElementLabelWidth.Max]: 'Max',
  [MappingElementLabelWidth.Long]: 'Long',
  [DashboardType.Reporting]: 'Customer card',
  [DashboardType.Skin]: 'Skin',
  [DashboardType.Vitality]: 'Vitality',
  [DashboardType.Basic]: 'Basic',
  [DashboardType.Wishes]: 'Wishes',
  [DashboardType.Wishes2]: 'Wishes+',
  [DashboardType.Advice]: 'Grow',
  [DashboardType.Documents]: 'Documents',
  [CustomerGender.Female]: 'Female',
  [CustomerGender.Male]: 'Male',
  [CustomerGender.Undefined]: 'Undefined',
  [EmployeeActivityType.CreateRole]: 'Create role',
  [EmployeeActivityType.CustomerCommentAdd]: 'Customer comment add',
  [EmployeeActivityType.CustomerCommentDelete]: 'Customer comment delete',
  [EmployeeActivityType.CustomerCommentUpdate]: 'Customer comment update',
  [EmployeeActivityType.CustomerCreate]: 'Customer create',
  [EmployeeActivityType.CustomerSkinAnalysisUpdate]: 'Customer skin analysis update',
  [EmployeeActivityType.CustomerUpdate]: 'Customer update',
  [EmployeeActivityType.CustomersImport]: 'Customers import',
  [EmployeeActivityType.CustomersSearch]: 'Customers search',
  [EmployeeActivityType.DashboardConfigurationUpdate]: 'Dashboard configuration update',
  [EmployeeActivityType.EmployeeCreate]: 'Employee create',
  [EmployeeActivityType.EmployeeUpdate]: 'Employee update',
  [EmployeeActivityType.OrganisationUpdate]: 'Organisation update',
  [EmployeeActivityType.PasswordResetRequest]: 'Password reset request',
  [EmployeeActivityType.QuestionnaireCreate]: 'Questionnaire create',
  [EmployeeActivityType.QuestionnaireUpdate]: 'Questionnaire update',
  [EmployeeActivityType.SalonCreate]: 'Branch create',
  [EmployeeActivityType.SalonUpdate]: 'Branch update',
  [EmployeeActivityType.SuccessfulPasswordReset]: 'Successful password reset',
  [EmployeeActivityType.UpdateRole]: 'Update role',
  [EmployeeActivityType.CreatePayment]: 'Create payment',
  [EmployeeActivityType.CancelSubscription]: 'Cancel subscription',
  [EmployeeActivityType.UpdateSubscription]: 'Subscription update',
  [EmployeeActivityType.CustomerHardDelete]: 'Customer hard delete',
  [EmployeeActivityType.WhatsappCreditsPaymentCreate]: 'Whatsapp credits payment create',
  [EmployeeActivityType.PaidCousePaymentCreate]: 'Course payment create',
  [EmployeeActivityType.CustomerConsentSend]: 'Customer consent sent',
  [EmployeeActivityType.CustomerDocumentSend]: 'Customer document sent',
  [EmployeeActivityType.CustomerDocumentDelete]: 'Customer document deleted',
  [CustomerCommentType.OnPoint]: 'On point',
  [CustomerCommentType.Products]: 'Products',
  [CustomerCommentType.SkinPlan]: 'Skin plan',
  [CustomerCommentType.Treatment]: 'Treatment',
  [AdviceType.CustomerAdvice]: 'Customer grow module',
  [AdviceType.EmployeeAdvice]: 'Employee grow module',
  [SubscriptionOptionFeature.Advice]: 'Grow module description',
  [SubscriptionOptionFeature.Wishes]: 'Wishes module description',
  [SubscriptionOptionFeature.Skin]: 'Skin module description',
  [SubscriptionOptionFeature.Vitality]: 'Vitality module description',
  [SubscriptionOptionFeature.Fingerprint]: 'Blueprint module description',
  [SubscriptionOptionFeature.Salon]: 'Branch(-es)',
  [SubscriptionOptionFeature.Employee]: 'Employee(-s)',
  [SubscriptionOptionFeature.Customer]: 'Customers',
  [OrganisationMessagingType.Email]: 'Email',
  [OrganisationMessagingType.Whatsapp]: 'Whatsapp',
  [CustomerConsentStatus.Accepted]: 'Accepted',
  [CustomerConsentStatus.Declined]: 'Declined',
  [CustomerConsentStatus.NotSeen]: 'Not seen',
  [CustomerConsentStatus.Seen]: 'Seen',
  [DocumentType.ConsentForm]: 'Consent form',
  [DocumentType.Document]: 'Information document',
};

export default translations;
